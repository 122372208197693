import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Deload Week`}</strong></p>
    <p>{`10 Rounds, not timed, of:`}</p>
    <p>{`10 Lateral Band Walks each way`}</p>
    <p>{`10/Leg Single Leg Squats (trailing leg on bar/box/bench behind you)`}</p>
    <p>{`10 Ball Leg Curls`}</p>
    <p>{`:20 Side Plank/Side`}</p>
    <p>{`then,`}</p>
    <p>{`Hip, low back, quad, abd/adductor, front rack mobility work.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      